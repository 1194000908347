import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import queryString from "query-string"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const Products = ({ data, location }) => {
  const tags = data.allContentfulCategory.nodes
  const [filter, setFilter] = useState({
    filteredData: [],
    tag: "",
    search: ""
  })
  useEffect(() => {
    const { q, c } = queryString.parse(decodeURIComponent(location.search))
    setFilter({
      ...filter,
      search: q ? q : "",
      tag: c ? c : ""
    })
  }, [location.search])
  const query = data.allContentfulProduct.edges
  const banner = data.allContentfulWeb.nodes[0].product
  let products = query
  if (filter.search !== "") {
    products = products.filter(({ node }) => node.name.toLowerCase().includes(filter.search))
  }
  if (filter.tag !== "") {
    products = products.filter(({ node }) => node.tags?.some(({ slug }) => slug === filter.tag))
  }

  // Get category name for SEO
  let categoryName = null
  for(let i=0; i< tags.length; i++) {
    if(tags[i]["slug"]===filter.tag) {
      categoryName = tags[i]["name"]
    }
  }
  return (
    <Layout location={location} search={filter.search}>
      <Seo title={`${categoryName || "Tất cả sản phẩm"}`} />
      <div
        className="page-header text-center"
        style={{ backgroundImage: `url('https://${banner.file.url}')` }}
      >
        <div className="container">
          <h1 className="page-title">
            <span>Sản phẩm</span>
          </h1>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="toolbox w-100">
            <div className="toolbox-right">
              <div className="toolbox-sort">
                <label>Thể loại:</label>
                <div className="select-custom">
                  <select
                    defaultValue={filter.tag}
                    className="form-control"
                    value={filter.tag}
                    onChange={e => navigate(`/products/?c=${e.target.value}`)}
                  >
                    <option value={""}>Tất cả</option>
                    {tags.map(c => (
                      <option key={c.id} value={c.slug}>{c.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="products mb-3">
            <div className="d-flex justify-content-around flex-wrap">
              {products.map(({ node }) => (
                <div className="col-sm-12 col-md-4 col-lg-3">
                  <div key={node.id} className="product product-7">
                    {node.thumbnail && (
                      <figure className="product-media">
                        <Link to={`/product/${node.slug}`} target={"_blank"} style={{ height: "282px" }}
                              className={"d-flex align-items-center"}>
                          <GatsbyImage
                            image={getImage(node.thumbnail[0])}
                            alt={node.thumbnail[0].title}
                            className="product-image"
                            height={280}
                          />
                        </Link>
                      </figure>)}
                    <div className="product-body text-center">
                      {node.tags && (
                        <div className="product-cat">
                          <Link
                            to={`/products/?tag=${node.tags.name}`}
                          >
                            {node.tags.name}
                          </Link>
                        </div>
                      )}
                      <h3 className="product-title">
                        <Link to={`/product/${node.slug}`} target={"_blank"}>
                          {node.name}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Products

export const pageQuery = graphql`
  query {
    allContentfulWeb {
      nodes {
        product {
          title
          file {
            url
          }
        }
      }
    }
    allContentfulCategory {
      nodes {
        id
        name
        slug
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          slug
          name
          tags {
            id
            name
            slug
          }
          thumbnail {
            title
            gatsbyImageData(formats: NO_CHANGE, height:280)
          }
        }
      }
    }
  }
`
